const lAudioContext =
  typeof AudioContext !== 'undefined'
    ? AudioContext
    : typeof webkitAudioContext !== 'undefined'
    ? webkitAudioContext
    : undefined;
let wasm;

const heap = new Array(128).fill(undefined);

heap.push(undefined, null, true, false);

function getObject(idx) {
  return heap[idx];
}

let heap_next = heap.length;

function addHeapObject(obj) {
  if (heap_next === heap.length) heap.push(heap.length + 1);
  const idx = heap_next;
  heap_next = heap[idx];

  heap[idx] = obj;
  return idx;
}

function dropObject(idx) {
  if (idx < 132) return;
  heap[idx] = heap_next;
  heap_next = idx;
}

function takeObject(idx) {
  const ret = getObject(idx);
  dropObject(idx);
  return ret;
}

const cachedTextDecoder =
  typeof TextDecoder !== 'undefined'
    ? new TextDecoder('utf-8', { ignoreBOM: true, fatal: true })
    : {
        decode: () => {
          throw Error('TextDecoder not available');
        },
      };

if (typeof TextDecoder !== 'undefined') {
  cachedTextDecoder.decode();
}

let cachedUint8Memory0 = null;

function getUint8Memory0() {
  if (cachedUint8Memory0 === null || cachedUint8Memory0.byteLength === 0) {
    cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer);
  }
  return cachedUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
  ptr = ptr >>> 0;
  return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

function isLikeNone(x) {
  return x === undefined || x === null;
}

let cachedFloat64Memory0 = null;

function getFloat64Memory0() {
  if (cachedFloat64Memory0 === null || cachedFloat64Memory0.byteLength === 0) {
    cachedFloat64Memory0 = new Float64Array(wasm.memory.buffer);
  }
  return cachedFloat64Memory0;
}

let cachedInt32Memory0 = null;

function getInt32Memory0() {
  if (cachedInt32Memory0 === null || cachedInt32Memory0.byteLength === 0) {
    cachedInt32Memory0 = new Int32Array(wasm.memory.buffer);
  }
  return cachedInt32Memory0;
}

let WASM_VECTOR_LEN = 0;

const cachedTextEncoder =
  typeof TextEncoder !== 'undefined'
    ? new TextEncoder('utf-8')
    : {
        encode: () => {
          throw Error('TextEncoder not available');
        },
      };

const encodeString =
  typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
        return cachedTextEncoder.encodeInto(arg, view);
      }
    : function (arg, view) {
        const buf = cachedTextEncoder.encode(arg);
        view.set(buf);
        return {
          read: arg.length,
          written: buf.length,
        };
      };

function passStringToWasm0(arg, malloc, realloc) {
  if (realloc === undefined) {
    const buf = cachedTextEncoder.encode(arg);
    const ptr = malloc(buf.length) >>> 0;
    getUint8Memory0()
      .subarray(ptr, ptr + buf.length)
      .set(buf);
    WASM_VECTOR_LEN = buf.length;
    return ptr;
  }

  let len = arg.length;
  let ptr = malloc(len) >>> 0;

  const mem = getUint8Memory0();

  let offset = 0;

  for (; offset < len; offset++) {
    const code = arg.charCodeAt(offset);
    if (code > 0x7f) break;
    mem[ptr + offset] = code;
  }

  if (offset !== len) {
    if (offset !== 0) {
      arg = arg.slice(offset);
    }
    ptr = realloc(ptr, len, (len = offset + arg.length * 3)) >>> 0;
    const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
    const ret = encodeString(arg, view);

    offset += ret.written;
  }

  WASM_VECTOR_LEN = offset;
  return ptr;
}

function debugString(val) {
  // primitive types
  const type = typeof val;
  if (type == 'number' || type == 'boolean' || val == null) {
    return `${val}`;
  }
  if (type == 'string') {
    return `"${val}"`;
  }
  if (type == 'symbol') {
    const description = val.description;
    if (description == null) {
      return 'Symbol';
    } else {
      return `Symbol(${description})`;
    }
  }
  if (type == 'function') {
    const name = val.name;
    if (typeof name == 'string' && name.length > 0) {
      return `Function(${name})`;
    } else {
      return 'Function';
    }
  }
  // objects
  if (Array.isArray(val)) {
    const length = val.length;
    let debug = '[';
    if (length > 0) {
      debug += debugString(val[0]);
    }
    for (let i = 1; i < length; i++) {
      debug += ', ' + debugString(val[i]);
    }
    debug += ']';
    return debug;
  }
  // Test for built-in
  const builtInMatches = /\[object ([^\]]+)\]/.exec(toString.call(val));
  let className;
  if (builtInMatches.length > 1) {
    className = builtInMatches[1];
  } else {
    // Failed to match the standard '[object ClassName]'
    return toString.call(val);
  }
  if (className == 'Object') {
    // we're a user defined class or Object
    // JSON.stringify avoids problems with cycles, and is generally much
    // easier than looping through ownProperties of `val`.
    try {
      return 'Object(' + JSON.stringify(val) + ')';
    } catch (_) {
      return 'Object';
    }
  }
  // errors
  if (val instanceof Error) {
    return `${val.name}: ${val.message}\n${val.stack}`;
  }
  // TODO we could test for more things here, like `Set`s and `Map`s.
  return className;
}

function makeMutClosure(arg0, arg1, dtor, f) {
  const state = { a: arg0, b: arg1, cnt: 1, dtor };
  const real = (...args) => {
    // First up with a closure we increment the internal reference
    // count. This ensures that the Rust closure environment won't
    // be deallocated while we're invoking it.
    state.cnt++;
    const a = state.a;
    state.a = 0;
    try {
      return f(a, state.b, ...args);
    } finally {
      if (--state.cnt === 0) {
        wasm.__wbindgen_export_2.get(state.dtor)(a, state.b);
      } else {
        state.a = a;
      }
    }
  };
  real.original = state;

  return real;
}
function __wbg_adapter_34(arg0, arg1, arg2) {
  wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h48dd35aace5826ec(
    arg0,
    arg1,
    addHeapObject(arg2)
  );
}

function __wbg_adapter_43(arg0, arg1) {
  wasm._dyn_core__ops__function__FnMut_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h61c674379511d134(
    arg0,
    arg1
  );
}

function __wbg_adapter_54(arg0, arg1, arg2) {
  wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h614cf5e5f36a5de6(
    arg0,
    arg1,
    addHeapObject(arg2)
  );
}

function __wbg_adapter_57(arg0, arg1) {
  wasm._dyn_core__ops__function__FnMut_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__hb59cb4ad280fb33a(
    arg0,
    arg1
  );
}

function __wbg_adapter_60(arg0, arg1, arg2) {
  wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h88d834d6980ac8df(
    arg0,
    arg1,
    addHeapObject(arg2)
  );
}

/**
 */
export function run_game() {
  wasm.run_game();
}

function handleError(f, args) {
  try {
    return f.apply(this, args);
  } catch (e) {
    wasm.__wbindgen_exn_store(addHeapObject(e));
  }
}

let cachedFloat32Memory0 = null;

function getFloat32Memory0() {
  if (cachedFloat32Memory0 === null || cachedFloat32Memory0.byteLength === 0) {
    cachedFloat32Memory0 = new Float32Array(wasm.memory.buffer);
  }
  return cachedFloat32Memory0;
}

function getArrayF32FromWasm0(ptr, len) {
  ptr = ptr >>> 0;
  return getFloat32Memory0().subarray(ptr / 4, ptr / 4 + len);
}

function getArrayI32FromWasm0(ptr, len) {
  ptr = ptr >>> 0;
  return getInt32Memory0().subarray(ptr / 4, ptr / 4 + len);
}

let cachedUint32Memory0 = null;

function getUint32Memory0() {
  if (cachedUint32Memory0 === null || cachedUint32Memory0.byteLength === 0) {
    cachedUint32Memory0 = new Uint32Array(wasm.memory.buffer);
  }
  return cachedUint32Memory0;
}

function getArrayU32FromWasm0(ptr, len) {
  ptr = ptr >>> 0;
  return getUint32Memory0().subarray(ptr / 4, ptr / 4 + len);
}

async function __wbg_load(module, imports) {
  if (typeof Response === 'function' && module instanceof Response) {
    if (typeof WebAssembly.instantiateStreaming === 'function') {
      try {
        return await WebAssembly.instantiateStreaming(module, imports);
      } catch (e) {
        if (module.headers.get('Content-Type') != 'application/wasm') {
          console.warn(
            '`WebAssembly.instantiateStreaming` failed because your server does not serve wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n',
            e
          );
        } else {
          throw e;
        }
      }
    }

    const bytes = await module.arrayBuffer();
    return await WebAssembly.instantiate(bytes, imports);
  } else {
    const instance = await WebAssembly.instantiate(module, imports);

    if (instance instanceof WebAssembly.Instance) {
      return { instance, module };
    } else {
      return instance;
    }
  }
}

function __wbg_get_imports() {
  const imports = {};
  imports.wbg = {};
  imports.wbg.__wbindgen_object_clone_ref = function (arg0) {
    const ret = getObject(arg0);
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_object_drop_ref = function (arg0) {
    takeObject(arg0);
  };
  imports.wbg.__wbindgen_cb_drop = function (arg0) {
    const obj = takeObject(arg0).original;
    if (obj.cnt-- == 1) {
      obj.a = 0;
      return true;
    }
    const ret = false;
    return ret;
  };
  imports.wbg.__wbindgen_string_new = function (arg0, arg1) {
    const ret = getStringFromWasm0(arg0, arg1);
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_number_get = function (arg0, arg1) {
    const obj = getObject(arg1);
    const ret = typeof obj === 'number' ? obj : undefined;
    getFloat64Memory0()[arg0 / 8 + 1] = isLikeNone(ret) ? 0 : ret;
    getInt32Memory0()[arg0 / 4 + 0] = !isLikeNone(ret);
  };
  imports.wbg.__wbindgen_is_null = function (arg0) {
    const ret = getObject(arg0) === null;
    return ret;
  };
  imports.wbg.__wbindgen_boolean_get = function (arg0) {
    const v = getObject(arg0);
    const ret = typeof v === 'boolean' ? (v ? 1 : 0) : 2;
    return ret;
  };
  imports.wbg.__wbindgen_string_get = function (arg0, arg1) {
    const obj = getObject(arg1);
    const ret = typeof obj === 'string' ? obj : undefined;
    var ptr1 = isLikeNone(ret)
      ? 0
      : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
  };
  imports.wbg.__wbindgen_number_new = function (arg0) {
    const ret = arg0;
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_log_c9486ca5d8e2cbe8 = function (arg0, arg1) {
    let deferred0_0;
    let deferred0_1;
    try {
      deferred0_0 = arg0;
      deferred0_1 = arg1;
      console.log(getStringFromWasm0(arg0, arg1));
    } finally {
      wasm.__wbindgen_free(deferred0_0, deferred0_1);
    }
  };
  imports.wbg.__wbg_log_aba5996d9bde071f = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5,
    arg6,
    arg7
  ) {
    let deferred0_0;
    let deferred0_1;
    try {
      deferred0_0 = arg0;
      deferred0_1 = arg1;
      console.log(
        getStringFromWasm0(arg0, arg1),
        getStringFromWasm0(arg2, arg3),
        getStringFromWasm0(arg4, arg5),
        getStringFromWasm0(arg6, arg7)
      );
    } finally {
      wasm.__wbindgen_free(deferred0_0, deferred0_1);
    }
  };
  imports.wbg.__wbg_mark_40e050a77cc39fea = function (arg0, arg1) {
    performance.mark(getStringFromWasm0(arg0, arg1));
  };
  imports.wbg.__wbg_measure_aa7a73f17813f708 = function () {
    return handleError(function (arg0, arg1, arg2, arg3) {
      let deferred0_0;
      let deferred0_1;
      let deferred1_0;
      let deferred1_1;
      try {
        deferred0_0 = arg0;
        deferred0_1 = arg1;
        deferred1_0 = arg2;
        deferred1_1 = arg3;
        performance.measure(
          getStringFromWasm0(arg0, arg1),
          getStringFromWasm0(arg2, arg3)
        );
      } finally {
        wasm.__wbindgen_free(deferred0_0, deferred0_1);
        wasm.__wbindgen_free(deferred1_0, deferred1_1);
      }
    }, arguments);
  };
  imports.wbg.__wbg_new_abda76e883ba8a5f = function () {
    const ret = new Error();
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_stack_658279fe44541cf6 = function (arg0, arg1) {
    const ret = getObject(arg1).stack;
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc
    );
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
  };
  imports.wbg.__wbg_error_f851667af71bcfc6 = function (arg0, arg1) {
    let deferred0_0;
    let deferred0_1;
    try {
      deferred0_0 = arg0;
      deferred0_1 = arg1;
      console.error(getStringFromWasm0(arg0, arg1));
    } finally {
      wasm.__wbindgen_free(deferred0_0, deferred0_1);
    }
  };
  imports.wbg.__wbg_crypto_70a96de3b6b73dac = function (arg0) {
    const ret = getObject(arg0).crypto;
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_is_object = function (arg0) {
    const val = getObject(arg0);
    const ret = typeof val === 'object' && val !== null;
    return ret;
  };
  imports.wbg.__wbg_process_dd1577445152112e = function (arg0) {
    const ret = getObject(arg0).process;
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_versions_58036bec3add9e6f = function (arg0) {
    const ret = getObject(arg0).versions;
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_node_6a9d28205ed5b0d8 = function (arg0) {
    const ret = getObject(arg0).node;
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_is_string = function (arg0) {
    const ret = typeof getObject(arg0) === 'string';
    return ret;
  };
  imports.wbg.__wbg_msCrypto_adbc770ec9eca9c7 = function (arg0) {
    const ret = getObject(arg0).msCrypto;
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_require_f05d779769764e82 = function () {
    return handleError(function () {
      const ret = module.require;
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbindgen_is_function = function (arg0) {
    const ret = typeof getObject(arg0) === 'function';
    return ret;
  };
  imports.wbg.__wbg_getRandomValues_3774744e221a22ad = function () {
    return handleError(function (arg0, arg1) {
      getObject(arg0).getRandomValues(getObject(arg1));
    }, arguments);
  };
  imports.wbg.__wbg_randomFillSync_e950366c42764a07 = function () {
    return handleError(function (arg0, arg1) {
      getObject(arg0).randomFillSync(takeObject(arg1));
    }, arguments);
  };
  imports.wbg.__wbg_instanceof_WebGl2RenderingContext_a9b8e563e17071fe =
    function (arg0) {
      let result;
      try {
        result = getObject(arg0) instanceof WebGL2RenderingContext;
      } catch {
        result = false;
      }
      const ret = result;
      return ret;
    };
  imports.wbg.__wbg_beginQuery_8f715b4ebd8dd609 = function (arg0, arg1, arg2) {
    getObject(arg0).beginQuery(arg1 >>> 0, getObject(arg2));
  };
  imports.wbg.__wbg_bindBufferRange_a27bbdf85c559847 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5
  ) {
    getObject(arg0).bindBufferRange(
      arg1 >>> 0,
      arg2 >>> 0,
      getObject(arg3),
      arg4,
      arg5
    );
  };
  imports.wbg.__wbg_bindSampler_b133997c35586e8e = function (arg0, arg1, arg2) {
    getObject(arg0).bindSampler(arg1 >>> 0, getObject(arg2));
  };
  imports.wbg.__wbg_bindVertexArray_ff82138c68ab11e2 = function (arg0, arg1) {
    getObject(arg0).bindVertexArray(getObject(arg1));
  };
  imports.wbg.__wbg_blitFramebuffer_642189de83d5541c = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5,
    arg6,
    arg7,
    arg8,
    arg9,
    arg10
  ) {
    getObject(arg0).blitFramebuffer(
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9 >>> 0,
      arg10 >>> 0
    );
  };
  imports.wbg.__wbg_bufferData_7a92a4df64dedbb5 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).bufferData(arg1 >>> 0, arg2, arg3 >>> 0);
  };
  imports.wbg.__wbg_bufferData_bc2f1a27f7162655 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).bufferData(arg1 >>> 0, getObject(arg2), arg3 >>> 0);
  };
  imports.wbg.__wbg_bufferSubData_dfaee0913e5a5aa9 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).bufferSubData(arg1 >>> 0, arg2, getObject(arg3));
  };
  imports.wbg.__wbg_clearBufferfi_885cba971ec9b951 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).clearBufferfi(arg1 >>> 0, arg2, arg3, arg4);
  };
  imports.wbg.__wbg_clearBufferfv_c3e8e1d2aae72740 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).clearBufferfv(
      arg1 >>> 0,
      arg2,
      getArrayF32FromWasm0(arg3, arg4)
    );
  };
  imports.wbg.__wbg_clearBufferiv_0d10229f924f144a = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).clearBufferiv(
      arg1 >>> 0,
      arg2,
      getArrayI32FromWasm0(arg3, arg4)
    );
  };
  imports.wbg.__wbg_clearBufferuiv_69b26948d51288f6 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).clearBufferuiv(
      arg1 >>> 0,
      arg2,
      getArrayU32FromWasm0(arg3, arg4)
    );
  };
  imports.wbg.__wbg_clientWaitSync_6ae34a595c426123 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    const ret = getObject(arg0).clientWaitSync(
      getObject(arg1),
      arg2 >>> 0,
      arg3 >>> 0
    );
    return ret;
  };
  imports.wbg.__wbg_compressedTexSubImage2D_50328fcf0a02e44e = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5,
    arg6,
    arg7,
    arg8,
    arg9
  ) {
    getObject(arg0).compressedTexSubImage2D(
      arg1 >>> 0,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7 >>> 0,
      arg8,
      arg9
    );
  };
  imports.wbg.__wbg_compressedTexSubImage2D_2f1e2aa4819eb8bf = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5,
    arg6,
    arg7,
    arg8
  ) {
    getObject(arg0).compressedTexSubImage2D(
      arg1 >>> 0,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7 >>> 0,
      getObject(arg8)
    );
  };
  imports.wbg.__wbg_compressedTexSubImage3D_b091ddadefb6d8bb = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5,
    arg6,
    arg7,
    arg8,
    arg9,
    arg10,
    arg11
  ) {
    getObject(arg0).compressedTexSubImage3D(
      arg1 >>> 0,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9 >>> 0,
      arg10,
      arg11
    );
  };
  imports.wbg.__wbg_compressedTexSubImage3D_7862a58f184543f9 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5,
    arg6,
    arg7,
    arg8,
    arg9,
    arg10
  ) {
    getObject(arg0).compressedTexSubImage3D(
      arg1 >>> 0,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9 >>> 0,
      getObject(arg10)
    );
  };
  imports.wbg.__wbg_copyBufferSubData_241eafcbd4c12698 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5
  ) {
    getObject(arg0).copyBufferSubData(arg1 >>> 0, arg2 >>> 0, arg3, arg4, arg5);
  };
  imports.wbg.__wbg_copyTexSubImage3D_a9e399a02a7b4913 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5,
    arg6,
    arg7,
    arg8,
    arg9
  ) {
    getObject(arg0).copyTexSubImage3D(
      arg1 >>> 0,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9
    );
  };
  imports.wbg.__wbg_createQuery_7d9135a15ef7f7c1 = function (arg0) {
    const ret = getObject(arg0).createQuery();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_createSampler_7cb18b6a7344cb7f = function (arg0) {
    const ret = getObject(arg0).createSampler();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_createVertexArray_c3ea33e844216f0c = function (arg0) {
    const ret = getObject(arg0).createVertexArray();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_deleteQuery_899eac2d10c353fe = function (arg0, arg1) {
    getObject(arg0).deleteQuery(getObject(arg1));
  };
  imports.wbg.__wbg_deleteSampler_fb860789f4f979fa = function (arg0, arg1) {
    getObject(arg0).deleteSampler(getObject(arg1));
  };
  imports.wbg.__wbg_deleteSync_29a2e0ba8b2b1fba = function (arg0, arg1) {
    getObject(arg0).deleteSync(getObject(arg1));
  };
  imports.wbg.__wbg_deleteVertexArray_6cac5e6d920ec62c = function (arg0, arg1) {
    getObject(arg0).deleteVertexArray(getObject(arg1));
  };
  imports.wbg.__wbg_drawArraysInstanced_5d47597735cf8309 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).drawArraysInstanced(arg1 >>> 0, arg2, arg3, arg4);
  };
  imports.wbg.__wbg_drawBuffers_95b0dc2f4a51d86c = function (arg0, arg1) {
    getObject(arg0).drawBuffers(getObject(arg1));
  };
  imports.wbg.__wbg_drawElementsInstanced_ca6817ccc8efd367 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5
  ) {
    getObject(arg0).drawElementsInstanced(
      arg1 >>> 0,
      arg2,
      arg3 >>> 0,
      arg4,
      arg5
    );
  };
  imports.wbg.__wbg_endQuery_8c211cc02cd01b20 = function (arg0, arg1) {
    getObject(arg0).endQuery(arg1 >>> 0);
  };
  imports.wbg.__wbg_fenceSync_be6e28c0ab054b7c = function (arg0, arg1, arg2) {
    const ret = getObject(arg0).fenceSync(arg1 >>> 0, arg2 >>> 0);
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_framebufferTextureLayer_4d37fce65ad6fa24 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5
  ) {
    getObject(arg0).framebufferTextureLayer(
      arg1 >>> 0,
      arg2 >>> 0,
      getObject(arg3),
      arg4,
      arg5
    );
  };
  imports.wbg.__wbg_getBufferSubData_00b00fcc39df3d04 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).getBufferSubData(arg1 >>> 0, arg2, getObject(arg3));
  };
  imports.wbg.__wbg_getIndexedParameter_3653fa5731165779 = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = getObject(arg0).getIndexedParameter(arg1 >>> 0, arg2 >>> 0);
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_getQueryParameter_10fdcafc2556d716 = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = getObject(arg0).getQueryParameter(getObject(arg1), arg2 >>> 0);
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_getSyncParameter_54a1c9c396fc8f04 = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = getObject(arg0).getSyncParameter(getObject(arg1), arg2 >>> 0);
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_getUniformBlockIndex_d7c6b4424fadb6d7 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    const ret = getObject(arg0).getUniformBlockIndex(
      getObject(arg1),
      getStringFromWasm0(arg2, arg3)
    );
    return ret;
  };
  imports.wbg.__wbg_invalidateFramebuffer_3722ca67172c0986 = function () {
    return handleError(function (arg0, arg1, arg2) {
      getObject(arg0).invalidateFramebuffer(arg1 >>> 0, getObject(arg2));
    }, arguments);
  };
  imports.wbg.__wbg_readBuffer_3b7ca5f65ff370fa = function (arg0, arg1) {
    getObject(arg0).readBuffer(arg1 >>> 0);
  };
  imports.wbg.__wbg_readPixels_4bb6684850f73e43 = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7
    ) {
      getObject(arg0).readPixels(
        arg1,
        arg2,
        arg3,
        arg4,
        arg5 >>> 0,
        arg6 >>> 0,
        getObject(arg7)
      );
    },
    arguments);
  };
  imports.wbg.__wbg_readPixels_dd16316dc9f7e055 = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7
    ) {
      getObject(arg0).readPixels(
        arg1,
        arg2,
        arg3,
        arg4,
        arg5 >>> 0,
        arg6 >>> 0,
        arg7
      );
    },
    arguments);
  };
  imports.wbg.__wbg_renderbufferStorageMultisample_edfed2d6d47a1ffc = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5
  ) {
    getObject(arg0).renderbufferStorageMultisample(
      arg1 >>> 0,
      arg2,
      arg3 >>> 0,
      arg4,
      arg5
    );
  };
  imports.wbg.__wbg_samplerParameterf_3de8180b6f0e0bad = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).samplerParameterf(getObject(arg1), arg2 >>> 0, arg3);
  };
  imports.wbg.__wbg_samplerParameteri_b48e56d39240ebfd = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).samplerParameteri(getObject(arg1), arg2 >>> 0, arg3);
  };
  imports.wbg.__wbg_texStorage2D_ea61cc78cf6dccc0 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5
  ) {
    getObject(arg0).texStorage2D(arg1 >>> 0, arg2, arg3 >>> 0, arg4, arg5);
  };
  imports.wbg.__wbg_texStorage3D_c0418a94dce44435 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5,
    arg6
  ) {
    getObject(arg0).texStorage3D(
      arg1 >>> 0,
      arg2,
      arg3 >>> 0,
      arg4,
      arg5,
      arg6
    );
  };
  imports.wbg.__wbg_texSubImage2D_c3c012dc814eb4de = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9
    ) {
      getObject(arg0).texSubImage2D(
        arg1 >>> 0,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7 >>> 0,
        arg8 >>> 0,
        getObject(arg9)
      );
    },
    arguments);
  };
  imports.wbg.__wbg_texSubImage2D_6cc58218d4d2218e = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9
    ) {
      getObject(arg0).texSubImage2D(
        arg1 >>> 0,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7 >>> 0,
        arg8 >>> 0,
        arg9
      );
    },
    arguments);
  };
  imports.wbg.__wbg_texSubImage2D_f2fc59cdbfe082f6 = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9
    ) {
      getObject(arg0).texSubImage2D(
        arg1 >>> 0,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7 >>> 0,
        arg8 >>> 0,
        getObject(arg9)
      );
    },
    arguments);
  };
  imports.wbg.__wbg_texSubImage2D_b80984a8394c2164 = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9
    ) {
      getObject(arg0).texSubImage2D(
        arg1 >>> 0,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7 >>> 0,
        arg8 >>> 0,
        getObject(arg9)
      );
    },
    arguments);
  };
  imports.wbg.__wbg_texSubImage2D_74b98607dfd0e706 = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9
    ) {
      getObject(arg0).texSubImage2D(
        arg1 >>> 0,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7 >>> 0,
        arg8 >>> 0,
        getObject(arg9)
      );
    },
    arguments);
  };
  imports.wbg.__wbg_texSubImage3D_46493f330c34c3b5 = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9,
      arg10,
      arg11
    ) {
      getObject(arg0).texSubImage3D(
        arg1 >>> 0,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7,
        arg8,
        arg9 >>> 0,
        arg10 >>> 0,
        arg11
      );
    },
    arguments);
  };
  imports.wbg.__wbg_texSubImage3D_a6a842c4612308e8 = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9,
      arg10,
      arg11
    ) {
      getObject(arg0).texSubImage3D(
        arg1 >>> 0,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7,
        arg8,
        arg9 >>> 0,
        arg10 >>> 0,
        getObject(arg11)
      );
    },
    arguments);
  };
  imports.wbg.__wbg_texSubImage3D_baa311b32dcdc729 = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9,
      arg10,
      arg11
    ) {
      getObject(arg0).texSubImage3D(
        arg1 >>> 0,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7,
        arg8,
        arg9 >>> 0,
        arg10 >>> 0,
        getObject(arg11)
      );
    },
    arguments);
  };
  imports.wbg.__wbg_texSubImage3D_1e4f13bb2b1241ba = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9,
      arg10,
      arg11
    ) {
      getObject(arg0).texSubImage3D(
        arg1 >>> 0,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7,
        arg8,
        arg9 >>> 0,
        arg10 >>> 0,
        getObject(arg11)
      );
    },
    arguments);
  };
  imports.wbg.__wbg_texSubImage3D_6f1cfd7913d851c1 = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9,
      arg10,
      arg11
    ) {
      getObject(arg0).texSubImage3D(
        arg1 >>> 0,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7,
        arg8,
        arg9 >>> 0,
        arg10 >>> 0,
        getObject(arg11)
      );
    },
    arguments);
  };
  imports.wbg.__wbg_uniform2fv_5d6ac180f63f4c4d = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).uniform2fv(
      getObject(arg1),
      getArrayF32FromWasm0(arg2, arg3)
    );
  };
  imports.wbg.__wbg_uniform2iv_49d86b6eec9545b2 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).uniform2iv(
      getObject(arg1),
      getArrayI32FromWasm0(arg2, arg3)
    );
  };
  imports.wbg.__wbg_uniform3fv_e576b04dd8611cdd = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).uniform3fv(
      getObject(arg1),
      getArrayF32FromWasm0(arg2, arg3)
    );
  };
  imports.wbg.__wbg_uniform3iv_9977b4a6060ecef7 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).uniform3iv(
      getObject(arg1),
      getArrayI32FromWasm0(arg2, arg3)
    );
  };
  imports.wbg.__wbg_uniform4fv_4fb418ea47a23f30 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).uniform4fv(
      getObject(arg1),
      getArrayF32FromWasm0(arg2, arg3)
    );
  };
  imports.wbg.__wbg_uniform4iv_22e92f9fbaa43da2 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).uniform4iv(
      getObject(arg1),
      getArrayI32FromWasm0(arg2, arg3)
    );
  };
  imports.wbg.__wbg_uniformBlockBinding_d55da3e5fc253c29 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).uniformBlockBinding(
      getObject(arg1),
      arg2 >>> 0,
      arg3 >>> 0
    );
  };
  imports.wbg.__wbg_uniformMatrix2fv_f8a7c78e2a307606 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).uniformMatrix2fv(
      getObject(arg1),
      arg2 !== 0,
      getArrayF32FromWasm0(arg3, arg4)
    );
  };
  imports.wbg.__wbg_uniformMatrix3fv_5fb56cebdc61819c = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).uniformMatrix3fv(
      getObject(arg1),
      arg2 !== 0,
      getArrayF32FromWasm0(arg3, arg4)
    );
  };
  imports.wbg.__wbg_uniformMatrix4fv_43b24d28b294355e = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).uniformMatrix4fv(
      getObject(arg1),
      arg2 !== 0,
      getArrayF32FromWasm0(arg3, arg4)
    );
  };
  imports.wbg.__wbg_vertexAttribDivisor_4bdbd8a597592338 = function (
    arg0,
    arg1,
    arg2
  ) {
    getObject(arg0).vertexAttribDivisor(arg1 >>> 0, arg2 >>> 0);
  };
  imports.wbg.__wbg_vertexAttribIPointer_2c55e7fe6713fc22 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5
  ) {
    getObject(arg0).vertexAttribIPointer(
      arg1 >>> 0,
      arg2,
      arg3 >>> 0,
      arg4,
      arg5
    );
  };
  imports.wbg.__wbg_activeTexture_0c3957272c193058 = function (arg0, arg1) {
    getObject(arg0).activeTexture(arg1 >>> 0);
  };
  imports.wbg.__wbg_attachShader_cda29f0482c65440 = function (
    arg0,
    arg1,
    arg2
  ) {
    getObject(arg0).attachShader(getObject(arg1), getObject(arg2));
  };
  imports.wbg.__wbg_bindBuffer_6f9a2fa9ebc65b01 = function (arg0, arg1, arg2) {
    getObject(arg0).bindBuffer(arg1 >>> 0, getObject(arg2));
  };
  imports.wbg.__wbg_bindFramebuffer_829df2e0091b6fcb = function (
    arg0,
    arg1,
    arg2
  ) {
    getObject(arg0).bindFramebuffer(arg1 >>> 0, getObject(arg2));
  };
  imports.wbg.__wbg_bindRenderbuffer_21f89c9f18128e39 = function (
    arg0,
    arg1,
    arg2
  ) {
    getObject(arg0).bindRenderbuffer(arg1 >>> 0, getObject(arg2));
  };
  imports.wbg.__wbg_bindTexture_10219c0f804bff90 = function (arg0, arg1, arg2) {
    getObject(arg0).bindTexture(arg1 >>> 0, getObject(arg2));
  };
  imports.wbg.__wbg_blendColor_d2bb381c9bf679e9 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).blendColor(arg1, arg2, arg3, arg4);
  };
  imports.wbg.__wbg_blendEquation_6d2f257c64af825c = function (arg0, arg1) {
    getObject(arg0).blendEquation(arg1 >>> 0);
  };
  imports.wbg.__wbg_blendEquationSeparate_02dd5ec6a2c24f28 = function (
    arg0,
    arg1,
    arg2
  ) {
    getObject(arg0).blendEquationSeparate(arg1 >>> 0, arg2 >>> 0);
  };
  imports.wbg.__wbg_blendFunc_210b8c175df415a2 = function (arg0, arg1, arg2) {
    getObject(arg0).blendFunc(arg1 >>> 0, arg2 >>> 0);
  };
  imports.wbg.__wbg_blendFuncSeparate_f9dccffef5a98f44 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).blendFuncSeparate(
      arg1 >>> 0,
      arg2 >>> 0,
      arg3 >>> 0,
      arg4 >>> 0
    );
  };
  imports.wbg.__wbg_colorMask_1dfb9a91ae2b9e71 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).colorMask(arg1 !== 0, arg2 !== 0, arg3 !== 0, arg4 !== 0);
  };
  imports.wbg.__wbg_compileShader_6f505d659e2795e6 = function (arg0, arg1) {
    getObject(arg0).compileShader(getObject(arg1));
  };
  imports.wbg.__wbg_copyTexSubImage2D_d24471634d2806ea = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5,
    arg6,
    arg7,
    arg8
  ) {
    getObject(arg0).copyTexSubImage2D(
      arg1 >>> 0,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8
    );
  };
  imports.wbg.__wbg_createBuffer_0da7eb27184081a8 = function (arg0) {
    const ret = getObject(arg0).createBuffer();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_createFramebuffer_591225e0e871c0ca = function (arg0) {
    const ret = getObject(arg0).createFramebuffer();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_createProgram_535e1a7a84baa7ff = function (arg0) {
    const ret = getObject(arg0).createProgram();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_createRenderbuffer_bc3df425ce38e3a9 = function (arg0) {
    const ret = getObject(arg0).createRenderbuffer();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_createShader_b1a69c91a9abbcf9 = function (arg0, arg1) {
    const ret = getObject(arg0).createShader(arg1 >>> 0);
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_createTexture_f999863e1ff4544e = function (arg0) {
    const ret = getObject(arg0).createTexture();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_cullFace_cd7e0b26c9752ee2 = function (arg0, arg1) {
    getObject(arg0).cullFace(arg1 >>> 0);
  };
  imports.wbg.__wbg_deleteBuffer_d6466e666563f36a = function (arg0, arg1) {
    getObject(arg0).deleteBuffer(getObject(arg1));
  };
  imports.wbg.__wbg_deleteFramebuffer_7e53363364604b3c = function (arg0, arg1) {
    getObject(arg0).deleteFramebuffer(getObject(arg1));
  };
  imports.wbg.__wbg_deleteProgram_c6b502ab111ca429 = function (arg0, arg1) {
    getObject(arg0).deleteProgram(getObject(arg1));
  };
  imports.wbg.__wbg_deleteRenderbuffer_dd5cf6b493cb5957 = function (
    arg0,
    arg1
  ) {
    getObject(arg0).deleteRenderbuffer(getObject(arg1));
  };
  imports.wbg.__wbg_deleteShader_cbb128151b8b2b6b = function (arg0, arg1) {
    getObject(arg0).deleteShader(getObject(arg1));
  };
  imports.wbg.__wbg_deleteTexture_43dcaa158a7a967b = function (arg0, arg1) {
    getObject(arg0).deleteTexture(getObject(arg1));
  };
  imports.wbg.__wbg_depthFunc_6c9952a747eff1e4 = function (arg0, arg1) {
    getObject(arg0).depthFunc(arg1 >>> 0);
  };
  imports.wbg.__wbg_depthMask_cbb47d618b2d576f = function (arg0, arg1) {
    getObject(arg0).depthMask(arg1 !== 0);
  };
  imports.wbg.__wbg_depthRange_9e881c47e26c4451 = function (arg0, arg1, arg2) {
    getObject(arg0).depthRange(arg1, arg2);
  };
  imports.wbg.__wbg_disable_94431bed03515efb = function (arg0, arg1) {
    getObject(arg0).disable(arg1 >>> 0);
  };
  imports.wbg.__wbg_disableVertexAttribArray_6f95b891552695e8 = function (
    arg0,
    arg1
  ) {
    getObject(arg0).disableVertexAttribArray(arg1 >>> 0);
  };
  imports.wbg.__wbg_drawArrays_77814548b9e573f2 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).drawArrays(arg1 >>> 0, arg2, arg3);
  };
  imports.wbg.__wbg_enable_b36ff5159bc88f3d = function (arg0, arg1) {
    getObject(arg0).enable(arg1 >>> 0);
  };
  imports.wbg.__wbg_enableVertexAttribArray_7e45a67bd47ec1bc = function (
    arg0,
    arg1
  ) {
    getObject(arg0).enableVertexAttribArray(arg1 >>> 0);
  };
  imports.wbg.__wbg_framebufferRenderbuffer_906d97968d0d8e6d = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).framebufferRenderbuffer(
      arg1 >>> 0,
      arg2 >>> 0,
      arg3 >>> 0,
      getObject(arg4)
    );
  };
  imports.wbg.__wbg_framebufferTexture2D_1dc2c539b965175d = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5
  ) {
    getObject(arg0).framebufferTexture2D(
      arg1 >>> 0,
      arg2 >>> 0,
      arg3 >>> 0,
      getObject(arg4),
      arg5
    );
  };
  imports.wbg.__wbg_frontFace_9aa0c51c6e4b1b49 = function (arg0, arg1) {
    getObject(arg0).frontFace(arg1 >>> 0);
  };
  imports.wbg.__wbg_getActiveUniform_89a5bd32a9f76fe3 = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = getObject(arg0).getActiveUniform(getObject(arg1), arg2 >>> 0);
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_getExtension_6b00e2c6c766b6cb = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = getObject(arg0).getExtension(getStringFromWasm0(arg1, arg2));
      return isLikeNone(ret) ? 0 : addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_getParameter_9e1070be2e213377 = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg0).getParameter(arg1 >>> 0);
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_getProgramInfoLog_03d7941c48fa9179 = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = getObject(arg1).getProgramInfoLog(getObject(arg2));
    var ptr1 = isLikeNone(ret)
      ? 0
      : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
  };
  imports.wbg.__wbg_getProgramParameter_dd171792e4ba3184 = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = getObject(arg0).getProgramParameter(
      getObject(arg1),
      arg2 >>> 0
    );
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_getShaderInfoLog_c1cca646bf94aa17 = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = getObject(arg1).getShaderInfoLog(getObject(arg2));
    var ptr1 = isLikeNone(ret)
      ? 0
      : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
  };
  imports.wbg.__wbg_getShaderParameter_c1d89b570b67be37 = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = getObject(arg0).getShaderParameter(getObject(arg1), arg2 >>> 0);
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_getSupportedExtensions_675e4373f0fe08ca = function (arg0) {
    const ret = getObject(arg0).getSupportedExtensions();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_getUniformLocation_984bcb57f0539335 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    const ret = getObject(arg0).getUniformLocation(
      getObject(arg1),
      getStringFromWasm0(arg2, arg3)
    );
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_linkProgram_0a25df9d4086c8c9 = function (arg0, arg1) {
    getObject(arg0).linkProgram(getObject(arg1));
  };
  imports.wbg.__wbg_pixelStorei_2a2698776f2da87a = function (arg0, arg1, arg2) {
    getObject(arg0).pixelStorei(arg1 >>> 0, arg2);
  };
  imports.wbg.__wbg_polygonOffset_e77202d52b1290b6 = function (
    arg0,
    arg1,
    arg2
  ) {
    getObject(arg0).polygonOffset(arg1, arg2);
  };
  imports.wbg.__wbg_renderbufferStorage_1c98a13850038020 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).renderbufferStorage(arg1 >>> 0, arg2 >>> 0, arg3, arg4);
  };
  imports.wbg.__wbg_scissor_5bbf5da585fcd6cd = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).scissor(arg1, arg2, arg3, arg4);
  };
  imports.wbg.__wbg_shaderSource_5c55ce208ee2dc38 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).shaderSource(
      getObject(arg1),
      getStringFromWasm0(arg2, arg3)
    );
  };
  imports.wbg.__wbg_stencilFuncSeparate_cb264101eaecfcb0 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).stencilFuncSeparate(
      arg1 >>> 0,
      arg2 >>> 0,
      arg3,
      arg4 >>> 0
    );
  };
  imports.wbg.__wbg_stencilMask_6cad8d0399d80755 = function (arg0, arg1) {
    getObject(arg0).stencilMask(arg1 >>> 0);
  };
  imports.wbg.__wbg_stencilMaskSeparate_39a3bad615edec8b = function (
    arg0,
    arg1,
    arg2
  ) {
    getObject(arg0).stencilMaskSeparate(arg1 >>> 0, arg2 >>> 0);
  };
  imports.wbg.__wbg_stencilOpSeparate_ab649871fc6cd85b = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).stencilOpSeparate(
      arg1 >>> 0,
      arg2 >>> 0,
      arg3 >>> 0,
      arg4 >>> 0
    );
  };
  imports.wbg.__wbg_texParameteri_05700ca575d5f41d = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).texParameteri(arg1 >>> 0, arg2 >>> 0, arg3);
  };
  imports.wbg.__wbg_uniform1f_51ae9c9d19ab2a9e = function (arg0, arg1, arg2) {
    getObject(arg0).uniform1f(getObject(arg1), arg2);
  };
  imports.wbg.__wbg_uniform1i_ef0ff3d67b59f4de = function (arg0, arg1, arg2) {
    getObject(arg0).uniform1i(getObject(arg1), arg2);
  };
  imports.wbg.__wbg_uniform4f_56014e7614d24fb7 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5
  ) {
    getObject(arg0).uniform4f(getObject(arg1), arg2, arg3, arg4, arg5);
  };
  imports.wbg.__wbg_useProgram_f16b06e2ecdf168f = function (arg0, arg1) {
    getObject(arg0).useProgram(getObject(arg1));
  };
  imports.wbg.__wbg_vertexAttribPointer_c16280a7c840a534 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5,
    arg6
  ) {
    getObject(arg0).vertexAttribPointer(
      arg1 >>> 0,
      arg2,
      arg3 >>> 0,
      arg4 !== 0,
      arg5,
      arg6
    );
  };
  imports.wbg.__wbg_viewport_a79678835091995e = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).viewport(arg1, arg2, arg3, arg4);
  };
  imports.wbg.__wbg_instanceof_Window_c5579e140698a9dc = function (arg0) {
    let result;
    try {
      result = getObject(arg0) instanceof Window;
    } catch {
      result = false;
    }
    const ret = result;
    return ret;
  };
  imports.wbg.__wbg_document_508774c021174a52 = function (arg0) {
    const ret = getObject(arg0).document;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_navigator_957c9b40d49df205 = function (arg0) {
    const ret = getObject(arg0).navigator;
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_innerWidth_ceb3ac1666399ed7 = function () {
    return handleError(function (arg0) {
      const ret = getObject(arg0).innerWidth;
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_innerHeight_972bafa16334ae25 = function () {
    return handleError(function (arg0) {
      const ret = getObject(arg0).innerHeight;
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_devicePixelRatio_027b47127fcabea6 = function (arg0) {
    const ret = getObject(arg0).devicePixelRatio;
    return ret;
  };
  imports.wbg.__wbg_isSecureContext_5a6dd79ef5b87ba1 = function (arg0) {
    const ret = getObject(arg0).isSecureContext;
    return ret;
  };
  imports.wbg.__wbg_matchMedia_0dd51eaa41e54a4a = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = getObject(arg0).matchMedia(getStringFromWasm0(arg1, arg2));
      return isLikeNone(ret) ? 0 : addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_get_cbc14a9ed59215dd = function (arg0, arg1, arg2) {
    const ret = getObject(arg0)[getStringFromWasm0(arg1, arg2)];
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_cancelAnimationFrame_1e00b5639e850581 = function () {
    return handleError(function (arg0, arg1) {
      getObject(arg0).cancelAnimationFrame(arg1);
    }, arguments);
  };
  imports.wbg.__wbg_requestAnimationFrame_d28701d8e57998d1 = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg0).requestAnimationFrame(getObject(arg1));
      return ret;
    }, arguments);
  };
  imports.wbg.__wbg_clearTimeout_809c8f9ea15fece0 = function (arg0, arg1) {
    getObject(arg0).clearTimeout(arg1);
  };
  imports.wbg.__wbg_fetch_66df15eb00fb9843 = function (arg0, arg1, arg2) {
    const ret = getObject(arg0).fetch(getStringFromWasm0(arg1, arg2));
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_setTimeout_a71432ae24261750 = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = getObject(arg0).setTimeout(getObject(arg1), arg2);
      return ret;
    }, arguments);
  };
  imports.wbg.__wbg_error_75cacb398853d810 = function (arg0, arg1) {
    console.error(getObject(arg0), getObject(arg1));
  };
  imports.wbg.__wbg_bindVertexArrayOES_edb665af84add641 = function (
    arg0,
    arg1
  ) {
    getObject(arg0).bindVertexArrayOES(getObject(arg1));
  };
  imports.wbg.__wbg_createVertexArrayOES_72dc110fc4561db9 = function (arg0) {
    const ret = getObject(arg0).createVertexArrayOES();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_deleteVertexArrayOES_bb2f05cfcd49a758 = function (
    arg0,
    arg1
  ) {
    getObject(arg0).deleteVertexArrayOES(getObject(arg1));
  };
  imports.wbg.__wbg_instanceof_Response_7ade9a5a066d1a55 = function (arg0) {
    let result;
    try {
      result = getObject(arg0) instanceof Response;
    } catch {
      result = false;
    }
    const ret = result;
    return ret;
  };
  imports.wbg.__wbg_arrayBuffer_2693673868da65b7 = function () {
    return handleError(function (arg0) {
      const ret = getObject(arg0).arrayBuffer();
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_deltaX_0e9fe84a3998df5e = function (arg0) {
    const ret = getObject(arg0).deltaX;
    return ret;
  };
  imports.wbg.__wbg_deltaY_c24e1c19542b4ba4 = function (arg0) {
    const ret = getObject(arg0).deltaY;
    return ret;
  };
  imports.wbg.__wbg_deltaMode_998c8ea939f3998a = function (arg0) {
    const ret = getObject(arg0).deltaMode;
    return ret;
  };
  imports.wbg.__wbg_drawArraysInstancedANGLE_95ee4d978b2bcf41 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).drawArraysInstancedANGLE(arg1 >>> 0, arg2, arg3, arg4);
  };
  imports.wbg.__wbg_drawElementsInstancedANGLE_67f54c0a65dc2bba = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5
  ) {
    getObject(arg0).drawElementsInstancedANGLE(
      arg1 >>> 0,
      arg2,
      arg3 >>> 0,
      arg4,
      arg5
    );
  };
  imports.wbg.__wbg_vertexAttribDivisorANGLE_d7cf4fdd9b5abe72 = function (
    arg0,
    arg1,
    arg2
  ) {
    getObject(arg0).vertexAttribDivisorANGLE(arg1 >>> 0, arg2 >>> 0);
  };
  imports.wbg.__wbg_copyToChannel_c9876ff39d78bd27 = function () {
    return handleError(function (arg0, arg1, arg2, arg3) {
      getObject(arg0).copyToChannel(getArrayF32FromWasm0(arg1, arg2), arg3);
    }, arguments);
  };
  imports.wbg.__wbg_matches_03fa716e6d77d76f = function (arg0) {
    const ret = getObject(arg0).matches;
    return ret;
  };
  imports.wbg.__wbg_width_a0eafc5275e3f419 = function (arg0) {
    const ret = getObject(arg0).width;
    return ret;
  };
  imports.wbg.__wbg_height_44501524d7b0437d = function (arg0) {
    const ret = getObject(arg0).height;
    return ret;
  };
  imports.wbg.__wbg_framebufferTextureMultiviewOVR_d3d00b7e42809e9c = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5,
    arg6
  ) {
    getObject(arg0).framebufferTextureMultiviewOVR(
      arg1 >>> 0,
      arg2 >>> 0,
      getObject(arg3),
      arg4,
      arg5,
      arg6
    );
  };
  imports.wbg.__wbg_now_c97f243e7947c4ac = function (arg0) {
    const ret = getObject(arg0).now();
    return ret;
  };
  imports.wbg.__wbg_drawBuffersWEBGL_874be434ff8441a5 = function (arg0, arg1) {
    getObject(arg0).drawBuffersWEBGL(getObject(arg1));
  };
  imports.wbg.__wbg_destination_0ae9151d82904b60 = function (arg0) {
    const ret = getObject(arg0).destination;
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_currentTime_ff9abefab476bee8 = function (arg0) {
    const ret = getObject(arg0).currentTime;
    return ret;
  };
  imports.wbg.__wbg_newwithcontextoptions_f8b6c58f7fd782b0 = function () {
    return handleError(function (arg0) {
      const ret = new lAudioContext(getObject(arg0));
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_close_c4da68c7d05f0953 = function () {
    return handleError(function (arg0) {
      const ret = getObject(arg0).close();
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_createBuffer_fe5ace8400138ade = function () {
    return handleError(function (arg0, arg1, arg2, arg3) {
      const ret = getObject(arg0).createBuffer(arg1 >>> 0, arg2 >>> 0, arg3);
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_createBufferSource_eed5b111f3941d98 = function () {
    return handleError(function (arg0) {
      const ret = getObject(arg0).createBufferSource();
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_resume_089773cbb84b9f23 = function () {
    return handleError(function (arg0) {
      const ret = getObject(arg0).resume();
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_instanceof_HtmlCanvasElement_b2dfeaf97587c9fa = function (
    arg0
  ) {
    let result;
    try {
      result = getObject(arg0) instanceof HTMLCanvasElement;
    } catch {
      result = false;
    }
    const ret = result;
    return ret;
  };
  imports.wbg.__wbg_width_b1f2559ce447b1d9 = function (arg0) {
    const ret = getObject(arg0).width;
    return ret;
  };
  imports.wbg.__wbg_setwidth_196f4382488fd119 = function (arg0, arg1) {
    getObject(arg0).width = arg1 >>> 0;
  };
  imports.wbg.__wbg_height_0d9fffc5de313208 = function (arg0) {
    const ret = getObject(arg0).height;
    return ret;
  };
  imports.wbg.__wbg_setheight_6d295d03e1783969 = function (arg0, arg1) {
    getObject(arg0).height = arg1 >>> 0;
  };
  imports.wbg.__wbg_getContext_77e8ee1ed9b4b6dd = function () {
    return handleError(function (arg0, arg1, arg2, arg3) {
      const ret = getObject(arg0).getContext(
        getStringFromWasm0(arg1, arg2),
        getObject(arg3)
      );
      return isLikeNone(ret) ? 0 : addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_setbuffer_7e24ddf1f55394c0 = function (arg0, arg1) {
    getObject(arg0).buffer = getObject(arg1);
  };
  imports.wbg.__wbg_setonended_30c0596773a1dfc3 = function (arg0, arg1) {
    getObject(arg0).onended = getObject(arg1);
  };
  imports.wbg.__wbg_start_dc7a146b60dcc9b3 = function () {
    return handleError(function (arg0, arg1) {
      getObject(arg0).start(arg1);
    }, arguments);
  };
  imports.wbg.__wbg_videoWidth_3b49fdb5de96b51a = function (arg0) {
    const ret = getObject(arg0).videoWidth;
    return ret;
  };
  imports.wbg.__wbg_videoHeight_39749fb7b13f66b5 = function (arg0) {
    const ret = getObject(arg0).videoHeight;
    return ret;
  };
  imports.wbg.__wbg_charCode_00bfd6cbe7d11a1d = function (arg0) {
    const ret = getObject(arg0).charCode;
    return ret;
  };
  imports.wbg.__wbg_keyCode_48193538ac21d5a4 = function (arg0) {
    const ret = getObject(arg0).keyCode;
    return ret;
  };
  imports.wbg.__wbg_altKey_1796184c5e96a92b = function (arg0) {
    const ret = getObject(arg0).altKey;
    return ret;
  };
  imports.wbg.__wbg_ctrlKey_a6ae383772af67d4 = function (arg0) {
    const ret = getObject(arg0).ctrlKey;
    return ret;
  };
  imports.wbg.__wbg_shiftKey_0b1fd10d0674f847 = function (arg0) {
    const ret = getObject(arg0).shiftKey;
    return ret;
  };
  imports.wbg.__wbg_metaKey_e6e67f783888f56d = function (arg0) {
    const ret = getObject(arg0).metaKey;
    return ret;
  };
  imports.wbg.__wbg_key_2e1ec0c70a342064 = function (arg0, arg1) {
    const ret = getObject(arg1).key;
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc
    );
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
  };
  imports.wbg.__wbg_code_8cb09c9b243af9d2 = function (arg0, arg1) {
    const ret = getObject(arg1).code;
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc
    );
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
  };
  imports.wbg.__wbg_getModifierState_c10e4bb3bfb294eb = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = getObject(arg0).getModifierState(
      getStringFromWasm0(arg1, arg2)
    );
    return ret;
  };
  imports.wbg.__wbg_clientX_7ffcce9d4cf5ed8f = function (arg0) {
    const ret = getObject(arg0).clientX;
    return ret;
  };
  imports.wbg.__wbg_clientY_2340b057451d96fb = function (arg0) {
    const ret = getObject(arg0).clientY;
    return ret;
  };
  imports.wbg.__wbg_offsetX_10c81ba572d79577 = function (arg0) {
    const ret = getObject(arg0).offsetX;
    return ret;
  };
  imports.wbg.__wbg_offsetY_804852ad76e32718 = function (arg0) {
    const ret = getObject(arg0).offsetY;
    return ret;
  };
  imports.wbg.__wbg_ctrlKey_1c15f65d527fd45e = function (arg0) {
    const ret = getObject(arg0).ctrlKey;
    return ret;
  };
  imports.wbg.__wbg_shiftKey_1a7bf1612681d447 = function (arg0) {
    const ret = getObject(arg0).shiftKey;
    return ret;
  };
  imports.wbg.__wbg_altKey_9d54d122b642413a = function (arg0) {
    const ret = getObject(arg0).altKey;
    return ret;
  };
  imports.wbg.__wbg_metaKey_3c7419a9d32c95d1 = function (arg0) {
    const ret = getObject(arg0).metaKey;
    return ret;
  };
  imports.wbg.__wbg_button_88e86c8fe3039068 = function (arg0) {
    const ret = getObject(arg0).button;
    return ret;
  };
  imports.wbg.__wbg_buttons_4d9e357e5465236f = function (arg0) {
    const ret = getObject(arg0).buttons;
    return ret;
  };
  imports.wbg.__wbg_movementX_11f340b9cfee2521 = function (arg0) {
    const ret = getObject(arg0).movementX;
    return ret;
  };
  imports.wbg.__wbg_movementY_242ff6df733a4558 = function (arg0) {
    const ret = getObject(arg0).movementY;
    return ret;
  };
  imports.wbg.__wbg_body_db30cc67afcfce41 = function (arg0) {
    const ret = getObject(arg0).body;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_fullscreenElement_205da47db7d0dfe8 = function (arg0) {
    const ret = getObject(arg0).fullscreenElement;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_createElement_d975e66d06bc88da = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = getObject(arg0).createElement(getStringFromWasm0(arg1, arg2));
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_exitFullscreen_bc78119e6342e4cb = function (arg0) {
    getObject(arg0).exitFullscreen();
  };
  imports.wbg.__wbg_exitPointerLock_230cc51f21dacdb8 = function (arg0) {
    getObject(arg0).exitPointerLock();
  };
  imports.wbg.__wbg_querySelector_41d5da02fa776534 = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = getObject(arg0).querySelector(getStringFromWasm0(arg1, arg2));
      return isLikeNone(ret) ? 0 : addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_getBoundingClientRect_89e65d65040347e7 = function (arg0) {
    const ret = getObject(arg0).getBoundingClientRect();
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_requestFullscreen_f22afd5334c6ff05 = function () {
    return handleError(function (arg0) {
      getObject(arg0).requestFullscreen();
    }, arguments);
  };
  imports.wbg.__wbg_requestPointerLock_235b61668584875b = function (arg0) {
    getObject(arg0).requestPointerLock();
  };
  imports.wbg.__wbg_setAttribute_1b177bcd399b9b56 = function () {
    return handleError(function (arg0, arg1, arg2, arg3, arg4) {
      getObject(arg0).setAttribute(
        getStringFromWasm0(arg1, arg2),
        getStringFromWasm0(arg3, arg4)
      );
    }, arguments);
  };
  imports.wbg.__wbg_setPointerCapture_a4d12ff87fb3c0d9 = function () {
    return handleError(function (arg0, arg1) {
      getObject(arg0).setPointerCapture(arg1);
    }, arguments);
  };
  imports.wbg.__wbg_style_6bc91a563e84d432 = function (arg0) {
    const ret = getObject(arg0).style;
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_bufferData_a8026dfac31d2906 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).bufferData(arg1 >>> 0, arg2, arg3 >>> 0);
  };
  imports.wbg.__wbg_bufferData_bf07fdd88f2e61a0 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).bufferData(arg1 >>> 0, getObject(arg2), arg3 >>> 0);
  };
  imports.wbg.__wbg_bufferSubData_af20b6998df1daf5 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).bufferSubData(arg1 >>> 0, arg2, getObject(arg3));
  };
  imports.wbg.__wbg_compressedTexSubImage2D_c2d0d8cbcaf08cec = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5,
    arg6,
    arg7,
    arg8
  ) {
    getObject(arg0).compressedTexSubImage2D(
      arg1 >>> 0,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7 >>> 0,
      getObject(arg8)
    );
  };
  imports.wbg.__wbg_readPixels_7e99f56bb0beeea6 = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7
    ) {
      getObject(arg0).readPixels(
        arg1,
        arg2,
        arg3,
        arg4,
        arg5 >>> 0,
        arg6 >>> 0,
        getObject(arg7)
      );
    },
    arguments);
  };
  imports.wbg.__wbg_texSubImage2D_3393b2faf4bdeda7 = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9
    ) {
      getObject(arg0).texSubImage2D(
        arg1 >>> 0,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7 >>> 0,
        arg8 >>> 0,
        getObject(arg9)
      );
    },
    arguments);
  };
  imports.wbg.__wbg_uniform2fv_eb1d8ba39134b63f = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).uniform2fv(
      getObject(arg1),
      getArrayF32FromWasm0(arg2, arg3)
    );
  };
  imports.wbg.__wbg_uniform2iv_ddd3395ddb25e28f = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).uniform2iv(
      getObject(arg1),
      getArrayI32FromWasm0(arg2, arg3)
    );
  };
  imports.wbg.__wbg_uniform3fv_51ce1d090ff61eb8 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).uniform3fv(
      getObject(arg1),
      getArrayF32FromWasm0(arg2, arg3)
    );
  };
  imports.wbg.__wbg_uniform3iv_72a700fef0840b5b = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).uniform3iv(
      getObject(arg1),
      getArrayI32FromWasm0(arg2, arg3)
    );
  };
  imports.wbg.__wbg_uniform4fv_329033dc8e0d864b = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).uniform4fv(
      getObject(arg1),
      getArrayF32FromWasm0(arg2, arg3)
    );
  };
  imports.wbg.__wbg_uniform4iv_8b0f52426db76864 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).uniform4iv(
      getObject(arg1),
      getArrayI32FromWasm0(arg2, arg3)
    );
  };
  imports.wbg.__wbg_uniformMatrix2fv_43aab1b3ba44f113 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).uniformMatrix2fv(
      getObject(arg1),
      arg2 !== 0,
      getArrayF32FromWasm0(arg3, arg4)
    );
  };
  imports.wbg.__wbg_uniformMatrix3fv_4e8c5fac9b4e2596 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).uniformMatrix3fv(
      getObject(arg1),
      arg2 !== 0,
      getArrayF32FromWasm0(arg3, arg4)
    );
  };
  imports.wbg.__wbg_uniformMatrix4fv_31d76bb58caa0246 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).uniformMatrix4fv(
      getObject(arg1),
      arg2 !== 0,
      getArrayF32FromWasm0(arg3, arg4)
    );
  };
  imports.wbg.__wbg_activeTexture_b191924c363f77ce = function (arg0, arg1) {
    getObject(arg0).activeTexture(arg1 >>> 0);
  };
  imports.wbg.__wbg_attachShader_c6ba0e94024fcfd3 = function (
    arg0,
    arg1,
    arg2
  ) {
    getObject(arg0).attachShader(getObject(arg1), getObject(arg2));
  };
  imports.wbg.__wbg_bindBuffer_bc746e5757cfd27a = function (arg0, arg1, arg2) {
    getObject(arg0).bindBuffer(arg1 >>> 0, getObject(arg2));
  };
  imports.wbg.__wbg_bindFramebuffer_a5e2ea2e2e5fea47 = function (
    arg0,
    arg1,
    arg2
  ) {
    getObject(arg0).bindFramebuffer(arg1 >>> 0, getObject(arg2));
  };
  imports.wbg.__wbg_bindRenderbuffer_18a9984850927cc8 = function (
    arg0,
    arg1,
    arg2
  ) {
    getObject(arg0).bindRenderbuffer(arg1 >>> 0, getObject(arg2));
  };
  imports.wbg.__wbg_bindTexture_003e92d5bd3d1cc7 = function (arg0, arg1, arg2) {
    getObject(arg0).bindTexture(arg1 >>> 0, getObject(arg2));
  };
  imports.wbg.__wbg_blendColor_eb27f199ff696720 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).blendColor(arg1, arg2, arg3, arg4);
  };
  imports.wbg.__wbg_blendEquation_e2282428514bc8da = function (arg0, arg1) {
    getObject(arg0).blendEquation(arg1 >>> 0);
  };
  imports.wbg.__wbg_blendEquationSeparate_c22d96f84170b893 = function (
    arg0,
    arg1,
    arg2
  ) {
    getObject(arg0).blendEquationSeparate(arg1 >>> 0, arg2 >>> 0);
  };
  imports.wbg.__wbg_blendFunc_7b891eca8bb9d4f9 = function (arg0, arg1, arg2) {
    getObject(arg0).blendFunc(arg1 >>> 0, arg2 >>> 0);
  };
  imports.wbg.__wbg_blendFuncSeparate_060a21922823f86f = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).blendFuncSeparate(
      arg1 >>> 0,
      arg2 >>> 0,
      arg3 >>> 0,
      arg4 >>> 0
    );
  };
  imports.wbg.__wbg_colorMask_8154d2f1a5eb697a = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).colorMask(arg1 !== 0, arg2 !== 0, arg3 !== 0, arg4 !== 0);
  };
  imports.wbg.__wbg_compileShader_11deea085b781c35 = function (arg0, arg1) {
    getObject(arg0).compileShader(getObject(arg1));
  };
  imports.wbg.__wbg_copyTexSubImage2D_5df950ef5a892ac1 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5,
    arg6,
    arg7,
    arg8
  ) {
    getObject(arg0).copyTexSubImage2D(
      arg1 >>> 0,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8
    );
  };
  imports.wbg.__wbg_createBuffer_d90b963a9701a002 = function (arg0) {
    const ret = getObject(arg0).createBuffer();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_createFramebuffer_011da022a209b95b = function (arg0) {
    const ret = getObject(arg0).createFramebuffer();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_createProgram_d78c1ab5ce988d0a = function (arg0) {
    const ret = getObject(arg0).createProgram();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_createRenderbuffer_f67deb1719aae5ab = function (arg0) {
    const ret = getObject(arg0).createRenderbuffer();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_createShader_94cce4c7315d3927 = function (arg0, arg1) {
    const ret = getObject(arg0).createShader(arg1 >>> 0);
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_createTexture_ae9908092d6ebd13 = function (arg0) {
    const ret = getObject(arg0).createTexture();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_cullFace_2aacef4bb0b82f8a = function (arg0, arg1) {
    getObject(arg0).cullFace(arg1 >>> 0);
  };
  imports.wbg.__wbg_deleteBuffer_10e438f4de8bbdd0 = function (arg0, arg1) {
    getObject(arg0).deleteBuffer(getObject(arg1));
  };
  imports.wbg.__wbg_deleteFramebuffer_8fce0ab5886cd5ff = function (arg0, arg1) {
    getObject(arg0).deleteFramebuffer(getObject(arg1));
  };
  imports.wbg.__wbg_deleteProgram_71b202b504e08521 = function (arg0, arg1) {
    getObject(arg0).deleteProgram(getObject(arg1));
  };
  imports.wbg.__wbg_deleteRenderbuffer_128a773568520d61 = function (
    arg0,
    arg1
  ) {
    getObject(arg0).deleteRenderbuffer(getObject(arg1));
  };
  imports.wbg.__wbg_deleteShader_91b6949c2cb57fa5 = function (arg0, arg1) {
    getObject(arg0).deleteShader(getObject(arg1));
  };
  imports.wbg.__wbg_deleteTexture_89b7cefee5715eaf = function (arg0, arg1) {
    getObject(arg0).deleteTexture(getObject(arg1));
  };
  imports.wbg.__wbg_depthFunc_ea602daadbf6bf2b = function (arg0, arg1) {
    getObject(arg0).depthFunc(arg1 >>> 0);
  };
  imports.wbg.__wbg_depthMask_32f5223c22963355 = function (arg0, arg1) {
    getObject(arg0).depthMask(arg1 !== 0);
  };
  imports.wbg.__wbg_depthRange_95b6305e3d0e8fb2 = function (arg0, arg1, arg2) {
    getObject(arg0).depthRange(arg1, arg2);
  };
  imports.wbg.__wbg_disable_18e601ffe079514a = function (arg0, arg1) {
    getObject(arg0).disable(arg1 >>> 0);
  };
  imports.wbg.__wbg_disableVertexAttribArray_eebd7bbc31fe8477 = function (
    arg0,
    arg1
  ) {
    getObject(arg0).disableVertexAttribArray(arg1 >>> 0);
  };
  imports.wbg.__wbg_drawArrays_fa797947fad0d5a1 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).drawArrays(arg1 >>> 0, arg2, arg3);
  };
  imports.wbg.__wbg_enable_933052fc623337dd = function (arg0, arg1) {
    getObject(arg0).enable(arg1 >>> 0);
  };
  imports.wbg.__wbg_enableVertexAttribArray_085d700612de8362 = function (
    arg0,
    arg1
  ) {
    getObject(arg0).enableVertexAttribArray(arg1 >>> 0);
  };
  imports.wbg.__wbg_framebufferRenderbuffer_54843e8f4bf613c4 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).framebufferRenderbuffer(
      arg1 >>> 0,
      arg2 >>> 0,
      arg3 >>> 0,
      getObject(arg4)
    );
  };
  imports.wbg.__wbg_framebufferTexture2D_36ac24bed50444b9 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5
  ) {
    getObject(arg0).framebufferTexture2D(
      arg1 >>> 0,
      arg2 >>> 0,
      arg3 >>> 0,
      getObject(arg4),
      arg5
    );
  };
  imports.wbg.__wbg_frontFace_cd5ca49e5c3379e0 = function (arg0, arg1) {
    getObject(arg0).frontFace(arg1 >>> 0);
  };
  imports.wbg.__wbg_getActiveUniform_9f6eb842d7ac3b6d = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = getObject(arg0).getActiveUniform(getObject(arg1), arg2 >>> 0);
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_getParameter_211f7166414ae1d2 = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg0).getParameter(arg1 >>> 0);
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_getProgramInfoLog_e9101bb2902f723d = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = getObject(arg1).getProgramInfoLog(getObject(arg2));
    var ptr1 = isLikeNone(ret)
      ? 0
      : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
  };
  imports.wbg.__wbg_getProgramParameter_2b03f89296b12b4b = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = getObject(arg0).getProgramParameter(
      getObject(arg1),
      arg2 >>> 0
    );
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_getShaderInfoLog_311c033a58aeed83 = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = getObject(arg1).getShaderInfoLog(getObject(arg2));
    var ptr1 = isLikeNone(ret)
      ? 0
      : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
  };
  imports.wbg.__wbg_getShaderParameter_0003e598f392b3f6 = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = getObject(arg0).getShaderParameter(getObject(arg1), arg2 >>> 0);
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_getUniformLocation_90a9c5e63646d30f = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    const ret = getObject(arg0).getUniformLocation(
      getObject(arg1),
      getStringFromWasm0(arg2, arg3)
    );
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_linkProgram_12cc552bbaf3fcd8 = function (arg0, arg1) {
    getObject(arg0).linkProgram(getObject(arg1));
  };
  imports.wbg.__wbg_pixelStorei_673b59ca3de9bf71 = function (arg0, arg1, arg2) {
    getObject(arg0).pixelStorei(arg1 >>> 0, arg2);
  };
  imports.wbg.__wbg_polygonOffset_43239add56a51f9c = function (
    arg0,
    arg1,
    arg2
  ) {
    getObject(arg0).polygonOffset(arg1, arg2);
  };
  imports.wbg.__wbg_renderbufferStorage_70c75c11a16f26e5 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).renderbufferStorage(arg1 >>> 0, arg2 >>> 0, arg3, arg4);
  };
  imports.wbg.__wbg_scissor_d6dd0b6d33070a27 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).scissor(arg1, arg2, arg3, arg4);
  };
  imports.wbg.__wbg_shaderSource_be3c868a31ce0a63 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).shaderSource(
      getObject(arg1),
      getStringFromWasm0(arg2, arg3)
    );
  };
  imports.wbg.__wbg_stencilFuncSeparate_6020e96c08ff2b26 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).stencilFuncSeparate(
      arg1 >>> 0,
      arg2 >>> 0,
      arg3,
      arg4 >>> 0
    );
  };
  imports.wbg.__wbg_stencilMask_b31337183c4f39b3 = function (arg0, arg1) {
    getObject(arg0).stencilMask(arg1 >>> 0);
  };
  imports.wbg.__wbg_stencilMaskSeparate_d829b104c43af459 = function (
    arg0,
    arg1,
    arg2
  ) {
    getObject(arg0).stencilMaskSeparate(arg1 >>> 0, arg2 >>> 0);
  };
  imports.wbg.__wbg_stencilOpSeparate_9efb207c561bddb9 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).stencilOpSeparate(
      arg1 >>> 0,
      arg2 >>> 0,
      arg3 >>> 0,
      arg4 >>> 0
    );
  };
  imports.wbg.__wbg_texParameteri_8a613af30322a029 = function (
    arg0,
    arg1,
    arg2,
    arg3
  ) {
    getObject(arg0).texParameteri(arg1 >>> 0, arg2 >>> 0, arg3);
  };
  imports.wbg.__wbg_uniform1f_47b794fe461d672a = function (arg0, arg1, arg2) {
    getObject(arg0).uniform1f(getObject(arg1), arg2);
  };
  imports.wbg.__wbg_uniform1i_eb9e0e57747e2b87 = function (arg0, arg1, arg2) {
    getObject(arg0).uniform1i(getObject(arg1), arg2);
  };
  imports.wbg.__wbg_uniform4f_d9261a15c9a46bc0 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5
  ) {
    getObject(arg0).uniform4f(getObject(arg1), arg2, arg3, arg4, arg5);
  };
  imports.wbg.__wbg_useProgram_72ab2082025590d6 = function (arg0, arg1) {
    getObject(arg0).useProgram(getObject(arg1));
  };
  imports.wbg.__wbg_vertexAttribPointer_39284763b38d2a03 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5,
    arg6
  ) {
    getObject(arg0).vertexAttribPointer(
      arg1 >>> 0,
      arg2,
      arg3 >>> 0,
      arg4 !== 0,
      arg5,
      arg6
    );
  };
  imports.wbg.__wbg_viewport_d7c73a71f08f3aa1 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4
  ) {
    getObject(arg0).viewport(arg1, arg2, arg3, arg4);
  };
  imports.wbg.__wbg_setProperty_0a5af0fd1a9e8e25 = function () {
    return handleError(function (arg0, arg1, arg2, arg3, arg4) {
      getObject(arg0).setProperty(
        getStringFromWasm0(arg1, arg2),
        getStringFromWasm0(arg3, arg4)
      );
    }, arguments);
  };
  imports.wbg.__wbg_target_bb43778021b84733 = function (arg0) {
    const ret = getObject(arg0).target;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_cancelBubble_42441ef40999b550 = function (arg0) {
    const ret = getObject(arg0).cancelBubble;
    return ret;
  };
  imports.wbg.__wbg_preventDefault_2f38e1471796356f = function (arg0) {
    getObject(arg0).preventDefault();
  };
  imports.wbg.__wbg_stopPropagation_5df9f972a70ef515 = function (arg0) {
    getObject(arg0).stopPropagation();
  };
  imports.wbg.__wbg_addEventListener_d25d1ffe6c69ae96 = function () {
    return handleError(function (arg0, arg1, arg2, arg3) {
      getObject(arg0).addEventListener(
        getStringFromWasm0(arg1, arg2),
        getObject(arg3)
      );
    }, arguments);
  };
  imports.wbg.__wbg_addEventListener_3a7d7c4177ce91d1 = function () {
    return handleError(function (arg0, arg1, arg2, arg3, arg4) {
      getObject(arg0).addEventListener(
        getStringFromWasm0(arg1, arg2),
        getObject(arg3),
        getObject(arg4)
      );
    }, arguments);
  };
  imports.wbg.__wbg_removeEventListener_2d55e9140c00c4a3 = function () {
    return handleError(function (arg0, arg1, arg2, arg3, arg4) {
      getObject(arg0).removeEventListener(
        getStringFromWasm0(arg1, arg2),
        getObject(arg3),
        getObject(arg4)
      );
    }, arguments);
  };
  imports.wbg.__wbg_getGamepads_94c59ef193fa09b6 = function () {
    return handleError(function (arg0) {
      const ret = getObject(arg0).getGamepads();
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_size_6b8408d071a01627 = function (arg0) {
    const ret = getObject(arg0).size;
    return ret;
  };
  imports.wbg.__wbg_type_3ef02d510696beba = function (arg0) {
    const ret = getObject(arg0).type;
    return ret;
  };
  imports.wbg.__wbg_name_b0536e0a02f4e559 = function (arg0, arg1) {
    const ret = getObject(arg1).name;
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc
    );
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
  };
  imports.wbg.__wbg_setchannelCount_50696509a55898f5 = function (arg0, arg1) {
    getObject(arg0).channelCount = arg1 >>> 0;
  };
  imports.wbg.__wbg_connect_6d125e4872b0bd49 = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg0).connect(getObject(arg1));
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_id_5fa848bf9a40e783 = function (arg0, arg1) {
    const ret = getObject(arg1).id;
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc
    );
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
  };
  imports.wbg.__wbg_index_7568a852288c91d0 = function (arg0) {
    const ret = getObject(arg0).index;
    return ret;
  };
  imports.wbg.__wbg_mapping_8d00149871e3efa2 = function (arg0) {
    const ret = getObject(arg0).mapping;
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_connected_4e0fb040794b3bb5 = function (arg0) {
    const ret = getObject(arg0).connected;
    return ret;
  };
  imports.wbg.__wbg_buttons_d5383e94d45c491a = function (arg0) {
    const ret = getObject(arg0).buttons;
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_axes_5bd938e67dbb0568 = function (arg0) {
    const ret = getObject(arg0).axes;
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_matches_de64b7bec89b21e4 = function (arg0) {
    const ret = getObject(arg0).matches;
    return ret;
  };
  imports.wbg.__wbg_addListener_23f9c4dc22403596 = function () {
    return handleError(function (arg0, arg1) {
      getObject(arg0).addListener(getObject(arg1));
    }, arguments);
  };
  imports.wbg.__wbg_removeListener_e161a73d82ead921 = function () {
    return handleError(function (arg0, arg1) {
      getObject(arg0).removeListener(getObject(arg1));
    }, arguments);
  };
  imports.wbg.__wbg_parentElement_065722829508e41a = function (arg0) {
    const ret = getObject(arg0).parentElement;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
  };
  imports.wbg.__wbg_appendChild_1139b53a65d69bed = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg0).appendChild(getObject(arg1));
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_maxChannelCount_22e52248701dba03 = function (arg0) {
    const ret = getObject(arg0).maxChannelCount;
    return ret;
  };
  imports.wbg.__wbg_instanceof_DomException_931f1a551d1e3f2d = function (arg0) {
    let result;
    try {
      result = getObject(arg0) instanceof DOMException;
    } catch {
      result = false;
    }
    const ret = result;
    return ret;
  };
  imports.wbg.__wbg_message_cdc7ca975e5bbd7a = function (arg0, arg1) {
    const ret = getObject(arg1).message;
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc
    );
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
  };
  imports.wbg.__wbg_x_638e31fe35a9d2a4 = function (arg0) {
    const ret = getObject(arg0).x;
    return ret;
  };
  imports.wbg.__wbg_y_dde3455efc81de6b = function (arg0) {
    const ret = getObject(arg0).y;
    return ret;
  };
  imports.wbg.__wbg_width_3f3962bb2721e365 = function (arg0) {
    const ret = getObject(arg0).width;
    return ret;
  };
  imports.wbg.__wbg_height_32cf02a714d68bd4 = function (arg0) {
    const ret = getObject(arg0).height;
    return ret;
  };
  imports.wbg.__wbg_pressed_9ee685e006ebf226 = function (arg0) {
    const ret = getObject(arg0).pressed;
    return ret;
  };
  imports.wbg.__wbg_width_3ccb03efe2122196 = function (arg0) {
    const ret = getObject(arg0).width;
    return ret;
  };
  imports.wbg.__wbg_height_756c13122fb83a8f = function (arg0) {
    const ret = getObject(arg0).height;
    return ret;
  };
  imports.wbg.__wbg_pointerId_438f8328b763cbac = function (arg0) {
    const ret = getObject(arg0).pointerId;
    return ret;
  };
  imports.wbg.__wbg_pressure_33715b4396315c0a = function (arg0) {
    const ret = getObject(arg0).pressure;
    return ret;
  };
  imports.wbg.__wbg_pointerType_6a490ed7bdef34c1 = function (arg0, arg1) {
    const ret = getObject(arg1).pointerType;
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc
    );
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
  };
  imports.wbg.__wbg_get_7303ed2ef026b2f5 = function (arg0, arg1) {
    const ret = getObject(arg0)[arg1 >>> 0];
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_length_820c786973abdd8a = function (arg0) {
    const ret = getObject(arg0).length;
    return ret;
  };
  imports.wbg.__wbg_new_0394642eae39db16 = function () {
    const ret = new Array();
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_newnoargs_c9e6043b8ad84109 = function (arg0, arg1) {
    const ret = new Function(getStringFromWasm0(arg0, arg1));
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_get_f53c921291c381bd = function () {
    return handleError(function (arg0, arg1) {
      const ret = Reflect.get(getObject(arg0), getObject(arg1));
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_call_557a2f2deacc4912 = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg0).call(getObject(arg1));
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_new_2b6fea4ea03b1b95 = function () {
    const ret = new Object();
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_self_742dd6eab3e9211e = function () {
    return handleError(function () {
      const ret = self.self;
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_window_c409e731db53a0e2 = function () {
    return handleError(function () {
      const ret = window.window;
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_globalThis_b70c095388441f2d = function () {
    return handleError(function () {
      const ret = globalThis.globalThis;
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_global_1c72617491ed7194 = function () {
    return handleError(function () {
      const ret = global.global;
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbindgen_is_undefined = function (arg0) {
    const ret = getObject(arg0) === undefined;
    return ret;
  };
  imports.wbg.__wbg_eval_3f756c8e9ad9b3df = function () {
    return handleError(function (arg0, arg1) {
      const ret = eval(getStringFromWasm0(arg0, arg1));
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_of_8276164206196da5 = function (arg0) {
    const ret = Array.of(getObject(arg0));
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_push_109cfc26d02582dd = function (arg0, arg1) {
    const ret = getObject(arg0).push(getObject(arg1));
    return ret;
  };
  imports.wbg.__wbg_call_587b30eea3e09332 = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = getObject(arg0).call(getObject(arg1), getObject(arg2));
      return addHeapObject(ret);
    }, arguments);
  };
  imports.wbg.__wbg_now_c857fb0367c762cc = function () {
    const ret = Date.now();
    return ret;
  };
  imports.wbg.__wbg_is_20a2e5c82eecc47d = function (arg0, arg1) {
    const ret = Object.is(getObject(arg0), getObject(arg1));
    return ret;
  };
  imports.wbg.__wbg_resolve_ae38ad63c43ff98b = function (arg0) {
    const ret = Promise.resolve(getObject(arg0));
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_then_8df675b8bb5d5e3c = function (arg0, arg1) {
    const ret = getObject(arg0).then(getObject(arg1));
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_then_835b073a479138e5 = function (arg0, arg1, arg2) {
    const ret = getObject(arg0).then(getObject(arg1), getObject(arg2));
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_buffer_55ba7a6b1b92e2ac = function (arg0) {
    const ret = getObject(arg0).buffer;
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_newwithbyteoffsetandlength_abbb764f85f16b6b = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = new Int8Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_newwithbyteoffsetandlength_6889cd5c48bfda26 = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = new Int16Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_newwithbyteoffsetandlength_23602c96f86e952a = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = new Int32Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_newwithbyteoffsetandlength_88d1d8be5df94b9b = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = new Uint8Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_new_09938a7d020f049b = function (arg0) {
    const ret = new Uint8Array(getObject(arg0));
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_set_3698e3ca519b3c3c = function (arg0, arg1, arg2) {
    getObject(arg0).set(getObject(arg1), arg2 >>> 0);
  };
  imports.wbg.__wbg_length_0aab7ffd65ad19ed = function (arg0) {
    const ret = getObject(arg0).length;
    return ret;
  };
  imports.wbg.__wbg_newwithbyteoffsetandlength_051cff28f608b6cb = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = new Uint16Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_newwithbyteoffsetandlength_fae0a69639559788 = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = new Uint32Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_newwithbyteoffsetandlength_ab5b524f83702d8d = function (
    arg0,
    arg1,
    arg2
  ) {
    const ret = new Float32Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_newwithlength_89eeca401d8918c2 = function (arg0) {
    const ret = new Uint8Array(arg0 >>> 0);
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_subarray_d82be056deb4ad27 = function (arg0, arg1, arg2) {
    const ret = getObject(arg0).subarray(arg1 >>> 0, arg2 >>> 0);
    return addHeapObject(ret);
  };
  imports.wbg.__wbg_set_07da13cc24b69217 = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = Reflect.set(
        getObject(arg0),
        getObject(arg1),
        getObject(arg2)
      );
      return ret;
    }, arguments);
  };
  imports.wbg.__wbindgen_debug_string = function (arg0, arg1) {
    const ret = debugString(getObject(arg1));
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc
    );
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
  };
  imports.wbg.__wbindgen_throw = function (arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
  };
  imports.wbg.__wbindgen_memory = function () {
    const ret = wasm.memory;
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_closure_wrapper1200 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 583, __wbg_adapter_34);
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_closure_wrapper1202 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 583, __wbg_adapter_34);
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_closure_wrapper1204 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 583, __wbg_adapter_34);
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_closure_wrapper1206 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 583, __wbg_adapter_34);
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_closure_wrapper1208 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 583, __wbg_adapter_43);
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_closure_wrapper1210 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 583, __wbg_adapter_34);
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_closure_wrapper1212 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 583, __wbg_adapter_34);
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_closure_wrapper1214 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 583, __wbg_adapter_34);
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_closure_wrapper1216 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 583, __wbg_adapter_34);
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_closure_wrapper1773 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 858, __wbg_adapter_54);
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_closure_wrapper19988 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 13362, __wbg_adapter_57);
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_closure_wrapper48171 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 32587, __wbg_adapter_60);
    return addHeapObject(ret);
  };

  return imports;
}

function __wbg_init_memory(imports, maybe_memory) {}

function __wbg_finalize_init(instance, module) {
  wasm = instance.exports;
  __wbg_init.__wbindgen_wasm_module = module;
  cachedFloat32Memory0 = null;
  cachedFloat64Memory0 = null;
  cachedInt32Memory0 = null;
  cachedUint32Memory0 = null;
  cachedUint8Memory0 = null;

  return wasm;
}

function initSync(module) {
  if (wasm !== undefined) return wasm;

  const imports = __wbg_get_imports();

  __wbg_init_memory(imports);

  if (!(module instanceof WebAssembly.Module)) {
    module = new WebAssembly.Module(module);
  }

  const instance = new WebAssembly.Instance(module, imports);

  return __wbg_finalize_init(instance, module);
}

async function __wbg_init(input) {
  if (wasm !== undefined) return wasm;

  if (typeof input === 'undefined') {
    input = new URL('game_lib_bg.wasm', import.meta.url);
  }
  const imports = __wbg_get_imports();

  if (
    typeof input === 'string' ||
    (typeof Request === 'function' && input instanceof Request) ||
    (typeof URL === 'function' && input instanceof URL)
  ) {
    input = fetch(input);
  }

  __wbg_init_memory(imports);

  const { instance, module } = await __wbg_load(await input, imports);

  return __wbg_finalize_init(instance, module);
}

export { initSync };
export default __wbg_init;
